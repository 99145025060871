import classNames from "classnames";
import { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";

import { MuteButton } from "../ui-components";
import styles from "./VideoSlide.module.scss";
import { videoSoundState } from "./videoSoundState";

interface VideoSlideProps {
  videoUrl: string;
  className?: string;
  isActive?: boolean;
  slideId: string;
}

const VideoSlide = ({
  videoUrl,
  className,
  isActive = true,
  slideId,
}: VideoSlideProps) => {
  const [videoSound, setVideoSound] = useRecoilState(videoSoundState);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (!isActive) {
      setVideoSound("");
    }
  }, [isActive]);

  useEffect(() => {
    const onVisibilityChange = () => {
      if (!videoRef.current) return;

      if (document.visibilityState === "visible") {
        videoRef.current.play().catch(() => undefined);
      } else {
        videoRef.current.pause();
      }
    };

    document.addEventListener("visibilitychange", onVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [isActive]);

  const toggleMute = () => {
    if (videoSound === slideId) {
      setVideoSound("");
    } else {
      setVideoSound(slideId);
    }
  };

  return (
    <div className={classNames(styles.root, className)}>
      <video
        className={classNames(styles.video, "swiper-lazy")}
        muted={videoSound !== slideId}
        autoPlay={true}
        loop={true}
        playsInline={true}
        preload="auto"
        src={videoUrl}
        ref={videoRef}
      />
      <MuteButton
        muted={videoSound !== slideId}
        toggle={toggleMute}
        className={styles.muteButton}
      />
    </div>
  );
};

export default VideoSlide;
