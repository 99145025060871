import { SlideSimple } from "@/api/generatedTypes";
import { VideoSlide } from "@/components/VideoSlide";

interface BaseSlideProps {
  slide: SlideSimple;
  slideClassName?: string;
  videoClassName?: string;
  onClick?(): void;
  isActive?: boolean;
}
const BaseSlide = ({
  slide,
  slideClassName = "",
  videoClassName = "",
  onClick,
  isActive,
}: BaseSlideProps) => {
  const isVideo = slide.content_type === "video";
  return (
    <div className={slideClassName} onClick={onClick}>
      {isVideo ? (
        <VideoSlide
          videoUrl={slide.url}
          className={videoClassName}
          isActive={isActive}
          slideId={slide.id}
        />
      ) : (
        <>
          <img
            src={slide.url}
            alt="look slide"
            loading="lazy"
            className="swiper-lazy"
          />
          <div className="swiper-lazy-preloader"></div>
        </>
      )}
    </div>
  );
};

export default BaseSlide;
